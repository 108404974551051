<script setup lang="ts">
import { debouncedRef } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

import { Box, CircularProgress, ErrorState, InputTextDropdown, Paper, Typography } from '@lasso/luikit'
import { useApi, useGoogleTagManager } from '@lasso/shared/hooks'
import { objEntries, objGroupBy } from '@lasso/shared/utils'

import { useSidenavApi } from '@sidenav/hooks/useSidenavApi'
import { generateGtmFixture } from '@sidenav/utils/gtm'

import SearchResultGroup from './SearchResultGroup.vue'

const googleTagManager = useGoogleTagManager()

const query = ref('')
const queryPrepared = debouncedRef(
  computed(() => {
    const normalizedQuery = query.value.trim()

    return normalizedQuery.length >= 3 ? normalizedQuery : ''
  }),
  300,
)

const { loading, data, error, retry } = useApi(
  useSidenavApi().getSearchResults,
  () => (queryPrepared.value ? [queryPrepared.value] : null),
  { refetch: true, clearWhenDisabled: true },
)

const groupEntries = computed(() => {
  const rawItems = data.value?.data ?? []
  const groups = objGroupBy(rawItems, (item) => {
    switch (item.entityType) {
      case 'adgroup':
        return 'AdGroups'
      case 'campaign':
        return 'Campaigns'
      case 'analytic':
        return 'Analytics'
      case 'audience':
        switch (item.entitySubType) {
          case 'location':
            return 'Locations'
          case 'inventory':
          case 'keyword':
          case 'blocking':
            return 'Brand Safety'
          case 'retargeting':
          case 'conversion':
          case 'web':
            return 'Digital Events'
          default:
            return 'Audience'
        }
      case 'creative':
        return 'Creatives'
      default:
        return 'Misc'
    }
  })

  if (groups['Brand Safety']) {
    groups['Brand Safety'].sort((a, b) => b.id - a.id)
  }
  if (groups['Digital Events']) {
    groups['Digital Events'].sort((a, b) => b.id - a.id)
  }

  return objEntries(groups)
})

const noResults = computed(() => groupEntries.value.length === 0)

watch(queryPrepared, (value) => {
  if (!value) {
    return
  }

  googleTagManager.triggerEvent(
    generateGtmFixture({
      name: 'search',
      el: 'global-search',
    }),
    {
      filterSideNav: {
        '@search': value,
      },
    },
  )
})

const onFocus = () => {
  googleTagManager.triggerEvent(generateGtmFixture({ name: 'search' }))
}
</script>

<template>
  <InputTextDropdown
    v-model="query"
    position="right"
    placeholder="Search"
    dataTestId="sidenav-global-search"
    class="global-search"
    name="global-search"
    width="500px"
    maxHeight="500px"
    size="lg"
    @focus="onFocus"
  >
    <template #dropdown="{ actions }">
      <Box v-if="loading" flex justify="center" p="4">
        <CircularProgress size="lg" />
      </Box>
      <ErrorState v-else-if="error" :error="error" @mousedown.prevent.stop @retry="retry()" />
      <template v-else-if="data">
        <Typography v-if="noResults">Nothing found for "{{ queryPrepared }}"</Typography>
        <Paper v-else class="sticky top-0 z-10" variant="contained" elevation="0" p="4">
          <Typography variant="overline1" color="textSecondary" uppercase>Search Results</Typography>
        </Paper>
        <Paper v-for="[key, items] in groupEntries" :key="key" px="4" pb="4">
          <SearchResultGroup :groupKey="key" :items="items" @close="actions.close" />
        </Paper>
      </template>
    </template>
  </InputTextDropdown>
</template>

<style scoped>
.global-search :deep(.dropdown-content) {
  @apply pl-2;
}
</style>
