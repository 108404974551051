import { computed, reactive } from 'vue'

import { useAccessControlBase, useUser, useUserFeatureToggles } from '@lasso/shared/hooks'
import { defineRefStore } from '@lasso/shared/utils'

export const useAccessControl = defineRefStore('accessControl', () => {
  const { isSuperAdmin } = useUser()
  const { feature, permission, hasPermission } = useAccessControlBase()
  const { isFeatureToggledOn } = useUserFeatureToggles()

  const permissions = reactive({
    audiences: permission('audiences'),
    campaigns: permission('campaigns'),
    creatives: permission('creatives'),
    digitalEvents: permission('webevents'),
    locations: permission('locations'),
    brandSafety: permission('inventorylists'),
    customWorkflows: isSuperAdmin,
    endemic: permission('monetization'),
    directSold: permission('directsoldcampaigns'),
    reporting: permission('reports'),
    measurement: computed(() => hasPermission('measurement') || hasPermission('analytics')),
    accounts: permission('accounts'),
    users: permission('users'),
    roles: isSuperAdmin,
    featurePreview: permission('features'),
    adminOnly: isSuperAdmin,
  })

  const features = reactive({
    campaignMonitoringV2: computed(() => isFeatureToggledOn('campaignMonitoringV2')),
    customWorkflows: feature('CUSTOMWORKFLOWS'),
    reportingV2: feature('REPORTING-2'),
    universalPixel: feature('UNIVERSAL_PIXELS'),
    accountSettingsPageV2: feature('ACCOUNT_SETTINGS_PAGE_V2'),
    adminPagesV2: feature('ADMIN_PAGES_V2'),
  })

  return { permissions, features }
})
