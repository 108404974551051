<script lang="ts" setup>
import { useToggle } from '@vueuse/core'
import { computed } from 'vue'

import { Box, Chip, Link, Paper, Typography } from '@lasso/luikit'

import { SearchResultDTO } from '@sidenav/api'
import { useAccessControl } from '@sidenav/hooks/useAccessControl'

const { groupKey, items } = defineProps<{
  groupKey: string
  items: SearchResultDTO[]
}>()

const emit = defineEmits<{
  close: []
}>()

const [isExpanded, toggleExpanded] = useToggle(false)
const expandText = computed(() => (isExpanded.value ? 'Collapse' : 'Expand'))
const itemsCount = computed(() => (items ? items?.length : 0))
const limitedItems = computed(() => (isExpanded.value ? items : items.slice(0, 5)))

const onClick = () => {
  emit('close')
}

const { features } = useAccessControl()

const getLink = (item: SearchResultDTO) => {
  if ((groupKey === 'Campaigns' || groupKey === 'AdGroups') && features.campaignMonitoringV2) {
    return item.linkNew || item.link
  }

  return item.link
}
</script>

<template>
  <Box>
    <Paper class="search-result-group" rounded="none" flex alignItems="center" justify="between">
      <Box flex alignItems="center" py="1">
        <Typography pr="2" variant="subtitle2" capitalize>
          {{ groupKey }}
        </Typography>
        <Chip size="2xs" :label="itemsCount" />
      </Box>
      <Link v-if="itemsCount > 5" color="secondary" @click="toggleExpanded()">
        <Typography variant="caption1" capitalize>
          {{ expandText }}
        </Typography>
      </Link>
    </Paper>
    <Box flex direction="col">
      <Box v-for="item in limitedItems" :key="item.id" flex alignItems="center" spaceX="2" mt="2">
        <Chip variant="outlined" size="2xs" :label="item.id" />
        <Typography variant="caption1" truncateRow="two" color="inherit" capitalize>
          <Link :to="getLink(item)" class="link link-hover" @click="onClick()">
            {{ item.name }}
          </Link>
        </Typography>
      </Box>
    </Box>
  </Box>
</template>

<style scoped>
.search-result-group {
  position: sticky;
  top: 50px;
}
</style>
