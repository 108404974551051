import { AxiosInstance } from 'axios'

import { bindApiMethods } from '@lasso/api-shared'
import { useApiCache } from '@lasso/shared/hooks'
import { createInjectionState } from '@lasso/shared/utils'

import { SidenavApi } from '../api'

export const [useProvideSidenavApi, useSidenavApi] = createInjectionState(
  (axiosInstance: AxiosInstance) => {
    const [{ sidenav }, methodsMapping] = bindApiMethods(new SidenavApi(axiosInstance))

    useApiCache().aliasCacheKeys(methodsMapping)

    return sidenav
  },
  { throwWhenNotProvided: 'Sidenav API was not provided' },
)
