<script lang="ts" setup>
import { useToggle } from '@vueuse/core'

import { Box } from '@lasso/luikit'

const model = defineModel<boolean>({ required: true })
const toggle = useToggle(model)
</script>

<template>
  <Box>
    <slot name="default" :toggle="toggle" :isOpen="model" />
    <Box v-if="model && $slots.dropdown" class="accordion-divider" ml="6">
      <slot name="dropdown" />
    </Box>
  </Box>
</template>

<style scoped>
.accordion-divider {
  @apply border-l border-solid;
}
</style>
