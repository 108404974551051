<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { hideLoader, showLoader } from '@lasso/shared/utils'

import { useInitApi } from '@sidenav/app/useInitApi'
import { useInitLuikit } from '@sidenav/app/useInitLuikit'

import { Sidenav } from './components/Sidenav'

useInitLuikit()
useInitApi()

const router = useRouter()
const ready = ref(false)

router.isReady().then(() => {
  ready.value = true
})

watch(
  () => ready.value,
  (value) => {
    if (value) {
      hideLoader('sidenav')
    } else {
      showLoader('sidenav')
    }
  },
  { immediate: true },
)

onUnmounted(() => {
  hideLoader('sidenav')
})
</script>

<template>
  <Sidenav v-if="ready" />
</template>
