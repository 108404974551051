import { useToggle } from '@vueuse/core'
import { computed } from 'vue'

import { useLocalStorage } from '@lasso/shared/hooks'
import { defineRefStore } from '@lasso/shared/utils'

export const useSidenav = defineRefStore('sidenav', () => {
  const collapsedInternal = useLocalStorage('sidenavCollapsed', false)
  const toggleCollapsed = useToggle(collapsedInternal)
  const [hovered, toggleHovered] = useToggle(false)
  const [collapseButtonVisible, showCollapseButton] = useToggle(false)
  const collapsed = computed(() => (collapsedInternal.value ? !hovered.value : false))

  return {
    collapsedInternal,
    collapsed,
    toggleCollapsed,

    hovered,
    toggleHovered,

    collapseButtonVisible,
    showCollapseButton,
  }
})
