<script lang="ts" setup>
import { computed } from 'vue'

import { Box, Link, Logo } from '@lasso/luikit'
import { useUser } from '@lasso/shared/hooks'
import { ComponentProps } from '@lasso/shared/types'
import { urlPublicAsset } from '@lasso/shared/utils'

import { useSidenav } from '@sidenav/hooks/useSidenav'

const LogoLong = urlPublicAsset('images/CompanyLogo.png')
const LogoShort = urlPublicAsset('images/CompanyShortLogo.png')

const { collapsed } = useSidenav()
const { authInfo } = useUser()

const logoBoxProps = computed(
  (): Partial<ComponentProps<typeof Box>> => ({
    justify: collapsed.value ? 'center' : 'start',
  }),
)

const logoSize = computed(() => (collapsed.value ? 'sm' : 'lg'))

const logoSrc = computed(() => (collapsed.value ? LogoShort : LogoLong))
</script>

<template>
  <Link :to="authInfo?.defaultPath ?? '/'">
    <Box flex v-bind="logoBoxProps">
      <Logo :src="logoSrc" alt="IQVIA Logo" :size="logoSize" />
    </Box>
  </Link>
</template>
