<script lang="ts" setup>
import { computed } from 'vue'

import { LinkBase, LinkType } from '@lasso/luikit'

const {
  to,
  selected,
  type = 'link',
} = defineProps<{
  to: string
  selected: boolean
  type?: LinkType
}>()

const emit = defineEmits<{
  click: []
}>()

const menuItemClasses = computed(() => {
  return {
    'menu-item-active': selected,
    'menu-item-inactive': !selected,
  }
})
</script>

<template>
  <LinkBase :to="to" :type="type" class="menu-item" :class="menuItemClasses" @click="emit('click')">
    <slot />
  </LinkBase>
</template>

<style scoped>
.menu-item {
  @apply px-3 my-0.5 py-2 select-none w-full rounded-full;
}

.menu-item-active {
  @apply text-primary bg-primary-light;
}

.menu-item-inactive {
  @apply text-textSecondary hover:bg-base-300 hover:text-textSecondary active:bg-base-500;
}
</style>
